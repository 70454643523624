<template>
  <div>
    <div class="row">
      <loading-notification :show="loading" />
      <retry-notification
        :show="error"
        @retry="updateData()"
      />
    </div>

    <div class="row">
      <div class="flex xs12 sm4">
        <va-card :title="$t('inventory_requests.view.title')">
          <actions
            slot="actions"
            crud-links="inventoryRequests"
            delete-route="inventory/requests"
            :actions="actions"
            :action-data="{id: $route.params.id}"
          />
          <text-list
            v-if="request.team"
            :condition="currentUser.can('Teams', 'view')"
            :title="$t('tables.headings.team')"
            :label="$t(request.team.code)"
            :to="{ name: 'teamsView', params: {id: request.team.id}}"
          />
          <text-list
            v-if="request.user"
            :condition="currentUser.can('Users', 'view')"
            :title="$t('tables.headings.user')"
            :label="request.user.name"
            :to="{ name: 'usersView', params: {id: request.user.id}}"
          />
          <text-list
            v-if="request.user"
            :title="$t('tables.headings.email')"
          >
            {{ request.user.email }}
          </text-list>
        </va-card>
      </div>
      <div class="flex xs12 sm8">
        <div class="row">
          <div class="flex xs12">
            <va-card :title="$t('prayers.view.request')">
              <div>{{ request.request }}</div>
            </va-card>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="flex xs12">
        <h6>{{ $t('tables.headings.image') }}</h6>
        <carousel v-if="request.inventory_requests_images && request.inventory_requests_images.length > 0">
          <slide
            v-for="image of request.inventory_requests_images"
            :key="image.id"
          >
            <img
              class="gallery-image"
              width="120px"
              v-lazy="getPath(image.image_path)"
              @click="showLightbox(image.image_path)"
            />
          </slide>
        </carousel>
        <div
          class="flex xs12"
          v-else
        >
          <span>{{$t('layout.empty')}}</span>
        </div>
        <light-box
          ref="lightbox"
          :show-caption="true"
          :show-light-box="false"
          :show-thumbs="false"
          :media="images"
        >
        </light-box>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Carousel, Slide } from 'vue-carousel'
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const TextList = () => import(/* webpackPrefetch: true */ '@/components/extras/Text/TextList')
const LightBox = () => import(/* webpackPrefetch: true */ 'vue-image-lightbox')

export default {
  name: 'inventory-request-view',
  components: {
    Actions,
    TextList,
    LightBox,
    Carousel,
    Slide,
  },
  data () {
    return {
      error: false,
      loading: false,
      request: {},
      images: [],
      actions: ['index', 'new', 'edit', 'delete'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `inventory/requests/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const requestId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(requestId))
      } catch (err) {
        // console.log('Error fetching request data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.request = { ...u.data.data }
      this.setImages(this.request)
    },
    getPath (url) {
      let base = this.$http.defaults.baseURL.slice(0)
      if (base) {
        if (/\/api\//g.test(base)) {
          base = base.replace('/api/', '')
        }
        if (!/^https?:\/\/[^/]+/g.test(base)) {
          base = window.origin + base
        }
        url = base + url
      }

      return url
    },
    setImages (request) {
      if (!request) {
        return
      }

      const images = []
      for (const requestImage of request.inventory_requests_images) {
        const i = {
          id: requestImage.id,
          src: this.getPath(requestImage.image_path),
        }

        images.push(i)
      }

      this.images = images.slice(0)
    },
    showLightbox: function (path) {
      path = this.getPath(path)

      const index = this.images.findIndex(i => i.src === path)
      this.$refs.lightbox.showImage(index)
    },
  },
}
</script>
